<template>
  <v-col cols="10" class="mx-auto">
    <v-card flat class="mt-0" :color="dark">
      <v-tabs background-color="transparent" v-if="tabShow">
        <v-tab key="tab1" to="/writer/all">All</v-tab>
        <v-tab key="tab2" to="/writer/invited">Invited</v-tab>
        <v-tab key="tab3" to="/writer/hidden">Hidden</v-tab>
      </v-tabs>
      <v-divider class="mt-0" color="grey" v-if="tabShow"></v-divider>
      <router-view class="mt-5"></router-view>
    </v-card>
  </v-col>
</template>

<script>
import colorMxn from "@/mixins/colorMxn";

export default {
  name: "availableOrders",
  mixins: [colorMxn],
  data() {
    return {};
  },
  computed: {
    tabShow() {
      let tabshow =
        this.$route.name === "in-progress" ||
        this.$route.name === "completed" ||
        this.$route.name === "cancelled"
          ? false
          : true;
      return tabshow;
    }
  }
};
</script>
